import React, { useEffect } from 'react';

import { ConfirmCancelModalRoot } from '../../components/ConfirmCancelModalContext';
import {
  HeaderLayout,
  HostNav,
  QueryBindSearchBar,
  type QueryBindSearchBarProps,
} from '../../components/Header';
import { HeaderAvatar } from '../../components/HeaderAvatar';
import { LPLogo } from '../../components/icons/LPLogo';
import { DefaultPageProvider } from '../../components/PageProvider';
import config from '../../config';
import {
  REDIRECT_CUSTOM_TARGET_MAP,
  useUserRedirect,
} from '../../hooks/useRedirection';

const HostHeader = (props: {
  search?: QueryBindSearchBarProps;
}): JSX.Element => {
  const left = (
    <>
      <LPLogo type='host' className='w-10 h-10' />
      <HostNav />
    </>
  );

  const right = (
    <>
      {props.search && <QueryBindSearchBar {...props.search} />}
      <HeaderAvatar />
    </>
  );

  return <HeaderLayout fill left={left} right={right} />;
};

export function HostView(props: {
  search?: QueryBindSearchBarProps;
  children: React.ReactNode;
  className?: string;
}): JSX.Element {
  const redirect = useUserRedirect();
  useEffect(() => {
    redirect([
      { kind: 'venueActivated', target: REDIRECT_CUSTOM_TARGET_MAP.noop },
      { kind: 'guest', target: config.app.homeUrlOrPathname },
      { kind: 'final', target: config.app.homeUrlOrPathname },
    ]);
  }, [redirect]);

  return (
    <DefaultPageProvider>
      <div className='w-full h-full bg-black flex flex-col'>
        <HostHeader search={props.search} />
        <div className={`flex-1 overflow-y-auto scrollbar ${props.className}`}>
          {props.children}
        </div>
      </div>
      <ConfirmCancelModalRoot />
    </DefaultPageProvider>
  );
}
